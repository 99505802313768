<template>
  <div id="about">
    <AppBar>
      <router-link to="/" title="Back">
        <BackIcon aria-hidden="true" />
      </router-link>
      <h1>About</h1>
    </AppBar>
    <div class="content">
      <p class="text">
        Stop Motion was developed by Gregor Parzefall. The first version of this
        app was created for a school project. It was using
        <a href="https://reactjs.org/" target="_blank">React</a>. This is a
        completely rewritten version that uses
        <a href="https://vuejs.org" target="_blank">Vue.js</a> instead of React.
      </p>
      <p>This app uses the following libraries:</p>
      <ul>
        <li>
          <a href="https://vuejs.org" target="_blank">Vue.js</a>
          <p>The Progressive JavaScript Framework</p>
        </li>
        <li>
          <a href="https://github.com/vuejs/vue-router" target="_blank">
            vue-router
          </a>
          <p>The official router for Vue.js</p>
        </li>
        <li>
          <a href="https://github.com/jakearchibald/idb" target="_blank">idb</a>
          <p>IndexedDB, but with promises</p>
        </li>
        <li>
          <a
            href="https://github.com/fontsource/fontsource/tree/master/packages/roboto#readme"
            target="_blank"
          >
            Fontsource Roboto
          </a>
          <p>CSS and web font files to easily self-host the “Roboto” font</p>
        </li>
        <li>
          <a
            href="https://github.com/google/material-design-icons"
            target="_blank"
          >
            Material Design icons
          </a>
          <p>Material Design icons by Google</p>
        </li>
        <li>
          <a href="https://github.com/uuidjs/uuid" target="_blank">uuid</a>
          <p>Generate RFC-compliant UUIDs in JavaScript</p>
        </li>
        <li>
          <a href="https://github.com/sindresorhus/array-move" target="_blank">
            array-move
          </a>
          <p>Move an array item to a different position</p>
        </li>
        <li>
          <a href="https://github.com/hjkcai/vue-modal-dialogs" target="_blank">
            vue-modal-dialogs
          </a>
          <p>Promisify dialogs in Vue</p>
        </li>
        <li>
          <a
            href="https://github.com/byteboomers/vue-autofocus-directive"
            target="_blank"
          >
            vue-autofocus-directive
          </a>
          <p>Autofocus directive for Vue</p>
        </li>
        <li>
          <a href="https://github.com/zloirock/core-js" target="_blank">
            core-js
          </a>
          <p>Standard Library</p>
        </li>
        <li>
          <a href="https://github.com/WICG/focus-visible" target="_blank">
            focus-visible
          </a>
          <p>Polyfill for <code>:focus-visible</code></p>
        </li>
        <li>
          <a href="https://github.com/GoogleChrome/workbox" target="_blank">
            Workbox
          </a>
          <p>JavaScript libraries for Progressive Web Apps</p>
        </li>
        <li>
          <a
            href="https://github.com/yyx990803/register-service-worker"
            target="_blank"
          >
            register-service-worker
          </a>
          <p>
            A script to simplify service worker registration with hooks for
            common events
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import BackIcon from "@/assets/material-design-icons/src/navigation/arrow_back/materialicons/24px.svg";

export default {
  name: "About",
  components: {
    AppBar,
    BackIcon,
  },
};
</script>

<style lang="scss">
@use "@/colors";

#about {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#about .content {
  padding: 0 8px;
  overflow: auto;
  color: colors.$fgLow;
}

#about .content .text {
  max-width: 610px;
  line-height: 1.5;
}

#about .content a {
  color: colors.$fg;
}

#about .content li {
  margin: 8px 0;
}
#about .content li p {
  margin-top: 8px;
  margin-bottom: 16px;
}
</style>
