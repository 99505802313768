<template>
  <img :src="url" v-on="$listeners" />
</template>

<script>
export default {
  name: "BlobImg",
  props: ["src"],
  data() {
    return {
      url: "",
    };
  },
  created() {
    this.url = URL.createObjectURL(this.src);
  },
  destroyed() {
    URL.revokeObjectURL(this.url);
  },
};
</script>
