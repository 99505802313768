<template>
  <div v-if="stream" id="recorder">
    <div class="button-spacer"></div>
    <video ref="video" aria-label="live camera preview" tabindex="-1" />
    <div class="button-spacer">
      <button
        class="take-photo-button"
        title="Take a photo"
        @click="takePhoto"
        :disabled="takePhotoDisabled"
      ></button>
    </div>
  </div>
  <div v-else-if="error" id="recorder-error">
    <div>
      <CameraErrorIcon
        width="48"
        height="48"
        viewBox="0 0 24 24"
        aria-hidden="true"
      />
      <p>Failed to access your camera!</p>
    </div>
  </div>
  <div v-else id="recorder-loading"><Loader /></div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import CameraErrorIcon from "@/assets/material-design-icons/src/av/videocam_off/materialicons/24px.svg";
import CameraSound from "@/assets/camera.wav";

export default {
  name: "Recorder",
  data() {
    const cameraSound = new Audio(CameraSound);
    cameraSound.addEventListener(
      "ended",
      () => (this.takePhotoDisabled = false)
    );

    return {
      stream: null,
      imgCapture: null,
      error: false,
      unmounted: false,

      cameraSound,
      takePhotoDisabled: false,
    };
  },
  async created() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        //use the best available resolution up to 4K
        video: {
          width: 4096,
          height: 2160,
        },
        audio: false,
        facingMode: "environment",
      });
    } catch (err) {
      this.error = true;
      return;
    }

    //don't try to attach the MediaStream to the <video> element if the component has already been unmounted before the user has allowed the app to access the camera
    //stop the MediaStream immediately instead
    if (this.unmounted) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
      return;
    }

    this.$nextTick(() => {
      this.$refs.video.srcObject = this.stream;
      this.$refs.video.addEventListener("canplay", () =>
        this.$refs.video.play()
      );
    });
  },
  destroyed() {
    this.unmounted = true;
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  },
  methods: {
    takePhoto() {
      const canvas = document.createElement("canvas");
      canvas.width = this.$refs.video.videoWidth;
      canvas.height = this.$refs.video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(this.$refs.video, 0, 0);

      this.cameraSound.play().then(() => (this.takePhotoDisabled = true));
      canvas.toBlob((blob) => {
        this.$emit("photo", blob);
      });
    },
  },
  components: {
    Loader,
    CameraErrorIcon,
  },
};
</script>

<style lang="scss">
@use "@/colors";

#recorder {
  flex-grow: 1;
  height: 0;
  display: flex;
  justify-content: space-evenly;
  background-color: black;
}

#recorder video {
  display: block;
  max-width: 100%;
  max-height: 100%;
  align-self: center;
}
#recorder video:focus {
  outline: none;
}

#recorder .button-spacer {
  max-width: 66px;
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
}

#recorder .take-photo-button {
  position: absolute;
  right: 8px;
  width: 50px;
  height: 50px;

  background-color: red;
  border: 5px solid white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.4);
}

#recorder .take-photo-button:focus {
  outline: none;
}
#recorder .take-photo-button.focus-visible {
  background: radial-gradient(#ff9999, #ff0000);
}

#recorder .take-photo-button:disabled {
  background: #b30000;
  border-color: #d9d9d9;
  pointer-events: none;
}

#recorder-error {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: colors.$fg;
}
#recorder-error p {
  font-size: 20px;
  margin: 0;
}
#recorder-error svg {
  color: red;
  margin-bottom: 8px;
}

#recorder-loading {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
