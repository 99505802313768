<template>
  <BaseDialog :aria-labelledby="id">
    <div class="content">
      <h1 :id="id" v-if="title">{{ title }}</h1>
      <p :id="!title ? id : undefined">{{ content }}</p>
    </div>
    <div class="buttons">
      <Button @click="$close">OK</Button>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from "./Base.vue";
import Button from "@/components/Button.vue";

export default {
  name: "AlertDialog",
  props: ["id", "title", "content"],
  components: {
    BaseDialog,
    Button,
  },
};
</script>
