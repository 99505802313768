<template>
  <li>
    <router-link :to="`/project/${project.id}`" class="project-list-item">
      <BlobImg
        v-if="this.project.frames.length > 0"
        :src="project.frames[previewFrameIndex].blob"
        aria-hidden="true"
      />
      <img
        v-else
        :src="require('@/assets/project-placeholder.png')"
        aria-hidden="true"
      />
      {{ project.name }}
    </router-link>
  </li>
</template>

<script>
import BlobImg from "@/components/BlobImg.vue";

export default {
  name: "ProjectListItem",
  props: ["project"],
  computed: {
    previewFrameIndex() {
      return Math.floor(this.project.frames.length / 2);
    },
  },
  components: {
    BlobImg,
  },
};
</script>

<style lang="scss">
@use "@/colors";

.project-list-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;

  background-color: #2c2c2c;
  color: colors.$fg;

  text-align: center;
  text-decoration: none;
  overflow-wrap: anywhere;
}

.project-list-item img {
  display: block;
  width: 100%;
  flex-grow: 1;
  object-fit: cover;
  margin-bottom: 8px;
}

.project-list-item:focus {
  outline: 2px solid currentColor;
}
</style>
