<template>
  <div v-if="project" id="project">
    <AppBar>
      <router-link to="/" title="Back">
        <BackIcon aria-hidden="true" />
      </router-link>
      <h1>{{ project.name }}</h1>
      <router-link :to="`${$route.path}/settings`" title="Settings">
        <SettingsIcon aria-hidden="true" />
      </router-link>
    </AppBar>
    <Recorder @photo="addFrame" />
  </div>
  <NotFoundError v-else-if="notFound" />
  <div v-else id="project-loading">
    <Loader />
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import BackIcon from "@/assets/material-design-icons/src/navigation/arrow_back/materialicons/24px.svg";
import SettingsIcon from "@/assets/material-design-icons/src/action/settings/materialicons/24px.svg";

import Recorder from "@/components/ProjectEditor/Recorder.vue";
import Loader from "@/components/Loader.vue";
import NotFoundError from "./NotFoundError.vue";
import { alert } from "@/components/dialog";

export default {
  name: "ProjectEditor",
  data() {
    return {
      project: null,
      notFound: false,
    };
  },
  methods: {
    async update() {
      let project;
      try {
        project = await this.$store.project(this.$route.params.id);
        if (!project) {
          this.notFound = true;
          return;
        }
        await this.$store.touchProject(this.$route.params.id);
      } catch (err) {
        await alert(
          "loadProjectErrorAlert",
          "IndexedDB error",
          `The project with the ID "${this.$route.params.id}" could not be retrieved from IndexedDB.`
        );
        this.$router.push("/");
        return;
      }
      this.project = project;
    },
    async addFrame(blob) {
      try {
        await this.$store.addFrame(this.project.id, blob);
      } catch (err) {
        alert(
          "frameSaveErrorAlert",
          "IndexedDB error",
          "The new frame could not be saved."
        );
      }
      this.update();
    },
  },
  async created() {
    this.update();
  },
  components: {
    AppBar,
    BackIcon,
    SettingsIcon,

    Recorder,
    Loader,
    NotFoundError,
  },
};
</script>

<style>
#project {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#project-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
