import Vue from "vue";
import VueRouter from "vue-router";

import Home from "./views/Home.vue";
import NewProjectForm from "./views/NewProjectForm.vue";
import ProjectEditor from "./views/ProjectEditor.vue";
import About from "./views/About.vue";
import NotFoundError from "./views/NotFoundError.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: Home },
  { path: "/new", component: NewProjectForm },
  { path: "/project/:id", component: ProjectEditor },
  { path: "/about", component: About },
  { path: "*", component: NotFoundError },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
