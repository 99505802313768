<template>
  <div id="home">
    <AppBar>
      <h1 id="app-title">Stop Motion</h1>
      <router-link to="/new" title="Create a new project">
        <AddIcon aria-hidden="true" />
      </router-link>
      <router-link to="/about" title="About">
        <InfoIcon aria-hidden="true" />
      </router-link>
    </AppBar>
    <ProjectList />
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import ProjectList from "@/components/Home/ProjectList.vue";

import AddIcon from "@/assets/material-design-icons/src/content/add/materialicons/24px.svg";
import InfoIcon from "@/assets/material-design-icons/src/action/info/materialiconsoutlined/24px.svg";

export default {
  name: "Home",
  components: {
    AppBar,
    ProjectList,
    AddIcon,
    InfoIcon,
  },
};
</script>

<style>
#home {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

h1#app-title {
  margin-left: 8px;
}
</style>
