<template>
  <div id="new-project">
    <AppBar>
      <router-link to="/" title="Back">
        <BackIcon aria-hidden="true" />
      </router-link>
      <h1>New project</h1>
    </AppBar>
    <div class="container">
      <form aria-label="New project" @submit.prevent="submit">
        <label for="name-input">Name</label>
        <Input
          type="text"
          v-model.trim="nameInput"
          v-autofocus
          id="name-input"
        />

        <label for="fps-input">Frame rate (in FPS)</label>
        <Input
          type="number"
          v-model.number="frameRateInput"
          id="frame-rate-input"
        />

        <Input type="submit" value="Create" :disabled="submitDisabled" />
      </form>
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import Input from "@/components/Input.vue";
import BackIcon from "@/assets/material-design-icons/src/navigation/arrow_back/materialicons/24px.svg";
import { alert } from "@/components/dialog";

export default {
  name: "NewProjectForm",
  components: {
    AppBar,
    Input,
    BackIcon,
  },
  data() {
    return {
      nameInput: "",
      frameRateInput: 18,
    };
  },
  computed: {
    submitDisabled() {
      return !(
        this.nameInput &&
        this.frameRateInput &&
        this.frameRateInput > 0 &&
        this.frameRateInput <= 30
      );
    },
  },
  methods: {
    async submit() {
      let id;
      try {
        id = await this.$store.createProject(
          this.nameInput,
          this.frameRateInput
        );
      } catch (err) {
        alert(
          "createProjectErrorAlert",
          "IndexedDB error",
          "The app could not create a new project."
        );
        return;
      }
      if (navigator.storage && navigator.storage.persist) {
        setTimeout(async () => {
          let isPersisted = await navigator.storage.persisted();
          const persistenceWasDenied = !!localStorage.getItem(
            "persistenceWasDenied"
          );
          if (!isPersisted && !persistenceWasDenied) {
            isPersisted = await navigator.storage.persist();
            if (!isPersisted) {
              alert(
                "persistentStorageDeniedAlert",
                "Storage error",
                "You (or your browser did it automatically, that's what Chrome does) have denied this app to store its data in persistent storage. This means that your browser may automatically delete your Stop Motion projects."
              );
              localStorage.setItem("persistenceWasDenied", "true");
            }
          }
        }, 0);
      }
      this.$router.replace(`/project/${id}`);
    },
  },
};
</script>

<style lang="scss">
@use "@/colors";

#new-project {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#new-project .container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

#new-project form {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 8px;
  color: colors.$fg;
}

@media screen and (min-height: 360px) {
  #new-project form {
    position: relative;
    top: -13.5px;
  }
}

#new-project input,
#new-project label {
  display: block;
  margin: 8px 0;
}
#new-project input {
  width: 100%;
}
#new-project label {
  margin-top: 16px;
}
#new-project label:first-child {
  margin-top: 0;
}
#new-project input[type="submit"] {
  margin-top: 32px;
}
</style>
