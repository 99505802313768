import { openDB } from "idb";
import { v4 as uuid } from "uuid";

class ProjectStore {
  constructor() {
    this.db = openDB("stop-motion", 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("projects")) {
          db.createObjectStore("projects", { keyPath: "id" });
        }
      },
    });
  }

  //Get all projects
  async projects() {
    return await (await this.db).getAll("projects");
  }
  //Get a project by ID
  async project(id) {
    return await (await this.db).get("projects", id);
  }

  //Create a new project
  async createProject(name, frameRate) {
    const id = uuid();
    await (await this.db).add("projects", {
      id,
      name,
      frameRate,
      frames: [],
      lastUsed: new Date(),
    });
    return id;
  }
  //Update the title or the frame rate of an existing project
  async updateProject(id, data) {
    const project = await this.project(id);
    if (!project) {
      throw new Error("The project you are trying to modify doesn't exist");
    }

    if (data.name) {
      project.name = data.name;
    }
    if (data.frameRate) {
      project.frameRate = data.frameRate;
    }

    await (await this.db).put("projects", project);
  }
  //Update the "last used" timestamp of a project
  async touchProject(id) {
    const project = await this.project(id);
    if (!project) {
      throw new Error("The project you are trying to modify doesn't exist");
    }
    project.lastUsed = new Date();
    await (await this.db).put("projects", project);
  }
  //Delete a project
  async deleteProject(id) {
    await (await this.db).delete("projects", id);
  }

  //Add a frame to an existing project
  async addFrame(projectId, blob) {
    const project = await this.project(projectId);
    if (!project) {
      throw new Error("The project you are trying to modify doesn't exist");
    }
    const id = uuid();
    project.frames.push({
      id,
      blob,
    });
    await (await this.db).put("projects", project);
    return id;
  }
  //Update a frame
  async updateFrame(projectId, id, blob) {
    const project = await this.project(projectId);
    if (!project) {
      throw new Error("The project you are trying to modify doesn't exist");
    }
    const index = project.frames.findIndex((frame) => frame.id === id);
    if (index === -1) {
      throw new Error("The frame you are trying to update doesn't exist");
    }
    project.frames[index].blob = blob;
    await (await this.db).put("projects", project);
  }
  //Delete a frame
  async deleteFrame(projectId, id) {
    const project = await this.project(projectId);
    if (!project) {
      throw new Error("The project you are trying to modify doesn't exist");
    }
    project.frames = project.frames.filter((frame) => frame.id !== id);
    await (await this.db).put("projects", project);
  }
}

export default ProjectStore;
