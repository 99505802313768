import Vue from "vue";
import * as ModalDialogs from "vue-modal-dialogs";
import autofocus from "vue-autofocus-directive";
import "focus-visible";
import "@fontsource/roboto";

import App from "./App.vue";
import ProjectStore from "./store";
import router from "./router";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.use(ModalDialogs);
Vue.directive("autofocus", autofocus);
Vue.prototype.$store = new ProjectStore();

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
