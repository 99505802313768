<template>
  <div class="dialog-background">
    <div class="dialog" role="dialog" aria-modal="true" v-bind="$attrs">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDialog",
  inheritAttrs: false,
};
</script>

<style lang="scss">
@use "@/colors";

.dialog-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
}

.dialog {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  width: 100%;
  max-width: 400px;
  overflow-wrap: anywhere;
  padding: 8px;

  background-color: colors.$bg;
  color: colors.$fg;
}

.dialog .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 0;
  flex-grow: 1;
}
.dialog .content h1 {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 16px;
}
.dialog .content p {
  margin: 0;
  line-height: 1.5;
  color: colors.$fgLow;
}
.dialog .content p:not(:last-child) {
  margin-bottom: 8px;
}
.dialog .content p:first-child {
  color: colors.$fg;
}

.dialog .buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 8px;
  border-top: 1px solid colors.$fgLow;
  padding-top: 8px;
}
.dialog .buttons button:not(:last-child) {
  margin-right: 8px;
}
</style>
