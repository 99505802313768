<template>
  <input v-on="listeners" />
</template>

<script>
export default {
  name: "BaseInput",
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit("input", event.target.value);
        },
      };
    },
  },
};
</script>

<style scoped>
input {
  background-color: #383838;
  padding: 8px;

  color: inherit;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  border-radius: 0;
  appearance: none;
}

input[type="submit"] {
  padding: 8px 16px;
  text-align: center;
  cursor: pointer;
}

input:focus {
  outline: 2px solid currentColor;
}

input:disabled {
  background-color: #242424;
  color: #8c8c8c;
  pointer-events: none;
}
</style>
