<template>
  <ul
    id="project-list"
    aria-label="Projects"
    v-if="projects && projects.length > 0"
  >
    <ProjectListItem
      v-for="project in projects"
      :project="project"
      :key="project.id"
    />
  </ul>
  <div id="project-list-empty" v-else-if="projects && projects.length === 0">
    <div>
      <h2>There aren't any projects yet!</h2>
      <p>
        You can create one by clicking on the
        <span class="button">
          <AddIcon aria-label="plus icon" />
        </span>
        in the app bar.
      </p>
    </div>
  </div>
  <div id="project-list-loading" v-else>
    <Loader />
  </div>
</template>

<script>
import ProjectListItem from "./ProjectListItem.vue";
import AddIcon from "@/assets/material-design-icons/src/content/add/materialicons/24px.svg";
import Loader from "@/components/Loader.vue";
import { alert } from "@/components/dialog";

export default {
  name: "ProjectList",
  components: {
    ProjectListItem,
    AddIcon,
    Loader,
  },
  data() {
    return {
      projects: null,
    };
  },
  async created() {
    let projects;
    try {
      projects = await this.$store.projects();
    } catch (err) {
      alert(
        "loadErrorAlert",
        "IndexedDB error",
        "The project list could not be retrieved from IndexedDB. Are you in private browsing mode? In some browsers, IndexedDB is not available in private browsing mode. If you are not in private browsing mode and this error still occurs, you should update your browser."
      );
      this.projects = [];
      return;
    }
    projects.sort((a, b) => b.lastUsed - a.lastUsed);
    this.projects = projects;
  },
};
</script>

<style lang="scss">
@use "@/colors";

#project-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 16px;
  overflow: auto;

  list-style: none;
  margin: 0;
}

#project-list-loading {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#project-list-empty {
  position: absolute;
  top: 49px;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;
}

#project-list-empty h2 {
  color: colors.$fg;
}
#project-list-empty p {
  color: colors.$fgLow;
}

#project-list-empty .button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 33px;
  margin: 0 4px;
  vertical-align: middle;

  background-color: colors.$bgAppBar;
  color: colors.$fg;
}
#project-list-empty .button > span {
  height: 24px;
}
</style>
